import React, { Component } from 'react';

class Footer extends Component {
  state = {};

  render() {
    return (
      <div className="footer">
        <a href="https://noveleffect.com/" target="_blank" rel="noreferrer">
          <span className="label">Home</span>
        </a>
        <a href="https://noveleffect.com/terms-of-service" target="_blank" rel="noreferrer">
          <span className="label">Terms</span>
        </a>
        <a href="https://noveleffect.com/privacy-policy" target="_blank" rel="noreferrer">
          <span className="label">Privacy Policy</span>
        </a>
        <a href="https://noveleffect.com/classroom-privacy-policy" target="_blank" rel="noreferrer">
          <span className="label">Classroom Privacy Policy</span>
        </a>
        <a href="https://noveleffect.com/patent" target="_blank" rel="noreferrer">
          <span className="label">Patent Information</span>
        </a>
        <div className="rights-reserved">© 2022 Novel Effect. All rights reserved.</div>
      </div>
    );
  }
}

export default Footer;
