import React, { Component } from 'react';

export default class HomePage extends Component {
  componentDidMount() {
    window.location.replace('https://noveleffect.com/school-plans/');
  }

  render() {
    return (
      <div />
    );
  }
}
