import React, { Component } from 'react';
import Bowser from 'bowser';
import classnames from 'classnames';
import './App.css';
import Header from './Components/Header';
import RedemptionForm from './Components/RedemptionForm';
import Footer from './Components/Footer';

class App extends Component {
  constructor(props) {
    super(props);

    this.state.browserCheckingFinished = true;
  }

  componentDidMount() {
    const userAgent = Bowser.getParser(window.navigator.userAgent);
    const browser = userAgent.getBrowserName();
    const browserVersion = userAgent.getBrowserVersion();
    const browserPlatform = userAgent.getPlatform();

    this.setState({
      devicePackage: {
        browser,
        browserVersion,
        browserPlatform,
        isMobile: browserPlatform.type === 'mobile',
        isTablet: browserPlatform.type === 'tablet'
      }
    });
  }

  state = {
    browserCheckingFinished: false,
    devicePackage: {
      isMobile: false,
      isTablet: false
    }
  };

  render() {
    const {
      devicePackage: {
        isMobile
      }
    } = this.state;

    return (
      <div className={classnames('app', {
        'mobile-platform': isMobile
      })}>
        <Header />
        <RedemptionForm />
        <Footer />
      </div>
    );
  }
}

export default App;
