import React, { Component } from 'react';
import logo from '../NovelEffectLogo_Home.png';

class Header extends Component {
  state = {};
  render() {
    return (
      <div className="header">
        <a href="https://noveleffect.com/" target="_blank" rel="noreferrer">
          <img src={logo} className="app-logo" alt="logo" />
        </a>
      </div>
    );
  }
}

export default Header;
