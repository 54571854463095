import React, { Component } from 'react';
import classnames from 'classnames';

const FORM_FIELDS = [
  { label: 'Your Name', key: 'teacher_name', type: 'text', required: false },
  { label: 'Your Email Address', key: 'teacher_email', type: 'email', required: true },
  { label: 'Plan ID', key: 'school_masked_id', type: 'text', required: true },
];

const BENEFIT_ITEMS = [
  'Unlimited read alouds',
  'Exclusive content unlocked',
  ' Access to all available ebooks',
  'Discounts on print book purchases​',
];

const REACT_APP_FIREBASE_DATABASE_URL = process.env.REACT_APP_FIREBASE_DATABASE_URL;
const REACT_APP_SCHOOLPASS_REDEMTION_ENDPOINT = process.env.REACT_APP_SCHOOLPASS_REDEMTION_ENDPOINT;
const ERROR_MESSSAGE = "Whoops! Something's not quite right. We are working on a fix ASAP. Please try again later or contact us at support@noveleffect.com";

class RedemptionForm extends Component {
  state = {
    district_name: '',
    error_message: null,
    is_loading: false,
    school_masked_id: '',
    school_name: '',
    success_message: null,
    teacher_email: '',
    teacher_name: '',
  };

  checkAndGenerateFormData = () => {
    const form_data = {};
    FORM_FIELDS.forEach(field => {
      form_data[field.key] = this.state[field.key].trim();
    });

    return form_data;
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleSubmit = () => {
    const url = `${ REACT_APP_FIREBASE_DATABASE_URL }/${ REACT_APP_SCHOOLPASS_REDEMTION_ENDPOINT }`;
    const body = this.checkAndGenerateFormData();

    this.setState({
      error_message: null,
      is_loading: true,
      success_message: null
    });

    fetch(url, {
      method: 'post',
      body: JSON.stringify(body),
      headers: { 'Content-Type': 'application/json' }
    }).then(res => {
      return res.json();
    }).then((data) => {
      const {
        status,
        message
      } = data;
      console.log('status code: ', status);
      if (status !== 200) {
        this.setState({
          error_message: ERROR_MESSSAGE,
          is_loading: false,
          success_message: null,
        });
      } else {
        this.setState({
          error_message: null,
          is_loading: false,
          success_message: message
        });
      }
    }).catch(error => {
      console.log(error);
      this.setState({
        error_message: ERROR_MESSSAGE,
        is_loading: false,
        success_message: null
      });
    });
  };

  render() {
    const {
      error_message,
      is_loading,
      success_message
    } = this.state;

    return (
      <div className="redemption-form">
        <div className="redemption-form-left-part">
          <div />
          <div className="para-class-a">
            You have a Novel Effect Premium Plan subscription to activate.
          </div>

          <div className="para-class-a">
            Incorporate the Novel Effect app in your regular <br />
            read aloud routine to <b>add interactive music and <br />
              sound effects </b> for your favorite picture books!
          </div>

          <div className="para-class-b">ACTIVATE TO ENJOY:</div>
          {
            BENEFIT_ITEMS.map(item => {
              return (
                <div className="list-item" key={item}>
                  <i className="fa fa-check check-icon" aria-hidden="true"></i>
                  <div>
                    {item}
                  </div>
                </div>
              );
            })
          }
        </div>
        <div className="redemption-form-right-part">
          <div className="para-class-c">
            Welcome! Activate your Premium Plan subscription
          </div>
          {
            FORM_FIELDS.map(field => {
              const fieldClass = classnames({
                'required': field.required
              });

              return (
                <div className="field" key={field.key}>
                  <label className={fieldClass}>{`${ field.label }:`}</label>
                  <input
                    type={field.type}
                    id={field.key}
                    name={field.key}
                    onChange={this.handleChange([field.key])}
                  />
                </div>
              );
            })
          }
          <div className="submit-button">
            <button
              type="submit"
              onClick={this.handleSubmit}
              disabled={is_loading}
            >
              {is_loading ? 'loading' : 'submit'}
            </button>
          </div>
          {
            error_message && <div className="error-message">{error_message}</div>
          }
          {
            success_message && <div className="success-message">{success_message}</div>
          }
          <div className={classnames('para-class-b', 'contact-us')}>Need help? <a href='https://noveleffect.com/contact/' target="_blank" rel="noreferrer">Contact Us</a></div>
        </div>
      </div >
    );
  }
}

export default RedemptionForm;
